// Footer.js

import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import footerImg2 from '../assets/images/footer.jpg'
import '../assets/css/navigation/footernew.css'; // Import your CSS file for styling
import { scrollToTop } from '../utils/scrollToTop';
const footernew = () => {

  // Handle Whatsapp Integration
	const phoneNumber = '+917066969991'; // Replace with the desired phone number
	const message = 'Hello!'; // Optional message

	const handleWhatsAppClick = () => {
		const whatsappLink = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
		window.location.href = whatsappLink;
	};

	// Handle call integration
	function handleCall() {
		const phoneNumber = '+917066969991'; // Replace with the phone number you want to call
		window.location.href = `tel:${phoneNumber}`;
	}

  return (
    <>
    <footer className="footer">
      <div className="container">
        <div className="useful-links">
          <h2 className='footer-heading section-inner-head'>Useful Links</h2>
          {/* Add your useful links here */}
          <ul className='userfull-link'>
              <li ><Link className='linkul' to="/" onClick={scrollToTop}>Home</Link></li>
              <li><Link className='linkul' to="/about" onClick={scrollToTop}>About Us</Link></li>
              <li><Link className='linkul' to="/mission-vision" onClick={scrollToTop}>Mission & Vision</Link></li>
              <li><Link className='linkul' to="/team" onClick={scrollToTop}>Our Team</Link></li>
              <li><Link className='linkul' to="/our-story" onClick={scrollToTop}>Our Story</Link></li>
              <li><Link className='linkul' to="/product" onClick={scrollToTop}>Products</Link></li>
              <li><Link className='linkul' to="/whyus" onClick={scrollToTop}>Why US</Link></li>
              <li><Link className='linkul' to="/contact" onClick={scrollToTop}>Contact Us</Link></li>
            {/* Add more links as needed */}
          </ul>
        </div>

        <div className="introduction">
        <h2 className='section-inner-head footer-heading'>Contact</h2>
          <p><span className='footer-heading section-inner-head' style={{fontSize: 20}}>Phone: </span><br />+91 7066969991</p><br /><br />
          <p><span className='footer-heading section-inner-head' style={{fontSize: 20}}>Email:</span> <br />info@oriphal.com<br/>contact@oriphal.com</p>
        </div>

        <div className="location-contact">
          <h2 className='footer-heading section-inner-head'>Location</h2>
          <p>Chaitanya Mega Market Indapur, Pune 413106.</p>
        </div>
        <div className="social-links">
          <h2 className='footer-heading section-inner-head'>Social Links</h2>
          {/* Add your social links with icons here */}
          <div className="social-icons">
              <Link className='linki' to="" target="_blank" rel="noopener noreferrer"><FaFacebook /></Link>
              <Link className='linki' to="https://www.instagram.com/ancestral_farmer_pc?igsh=YzVkODRmOTdmMw" target="_blank" rel="noopener noreferrer"><FaInstagram /></Link>
              <Link className='linki' to="https://www.linkedin.com/company/ancestral-farmer-producer-company-limited/" target="_blank" rel="noopener noreferrer"><FaLinkedin /></Link>
              <Link className='linki' to="" target="_blank" rel="noopener noreferrer"><FaYoutube /></Link>
          </div>
        </div>

      </div>
    </footer>
    <div className='footer-copyright'>© Copyright Oriphal. All Rights Reserved Powered by <a className="copy-right-link" href='https://brandliberty.in/' target="_blank">Brand Liberty</a></div>
    <div className='footer-img2'>
    </div>
    <div>
				{/* Whatsapp Integration */}
				<div className="wtsp-btn wp-hover-effect" onClick={handleWhatsAppClick}><i className="fa-brands fa-whatsapp wp-hover-effect"></i></div>

				{/*Call Integration*/}
				<div className="call-btn call-hover-effect" onClick={handleCall}><i className="fa-solid fa-phone call-hover-effect"></i></div>
			</div>
    </>
  );
};

export default footernew;
