import React, { useEffect, useState } from 'react';
import '../../assets/css/containers/blogs.css';

import { Link } from 'react-router-dom';
import Loader from '../../screen/Loader';
import { scrollToTop } from '../../utils/scrollToTop';
import blog1Image from '../../assets/images/Pages-slider/whyus.jpg';
import blog2Image from '../../assets/images/Pages-slider/whyus.jpg';
import blog3Image from '../../assets/images/Pages-slider/whyus.jpg';

const Blogs = () => {
    const [showPageLoader, setShowPageLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setShowPageLoader(false);
        }, 1650);
    }, []);

    const handleExploreMoreClick = () => {
        scrollToTop();
    };

    const blogs = [
        {
            id: 1,
            image: blog1Image,
            date: '10 march 2024',
            title: 'Pomegranate harvesting methods for farmers',
            author: 'Oriphal',
            link: '/blog_detail' 
        },
        {
            id: 2,
            image: blog2Image,
            date: '10 march 2024',
            title: 'Seasonal harvesting fruits in India',
            author: 'Oriphal',
            link: '/blog_detail' 
        },
        {
            id: 3,
            image: blog3Image,
            date: '10 march 2024',
            title: 'Market up’s and down’s depends on what',
            author: 'Oriphal',
            link: '/blog_detail' 
        }
    ];

    if (showPageLoader) {
        return <Loader />;
    } else {
        return (
            <section className="product-section-mt-5 product-pt-4 product-pb-5 product-div">
                <div className='blog-main'>
                    <div className="blogs-img-fluid about-img-fluid">
                        <h2 style={{ marginTop: -50 }} data-aos="fade-up">
                            Blogs
                        </h2>
                    </div>

                    <div className="blog-container">
                        {blogs.map(blog => (
                            <div className="blog-box" key={blog.id} >
                                <div className="blog-img">
                                    <img src={blog.image} alt="blog" />
                                </div>
                                <div className="blog-text">
                                    <span>{blog.date}</span>
                                    <Link to={blog.link} className="blog-title">{blog.title}</Link>
                                    <p>{blog.author}</p>
                                    <br />
                                    <Link to={blog.link} style={{ textDecoration: `none` }}>
                                        <button type="button" className="slide-btn-updated" onClick={handleExploreMoreClick}>
                                            Read More
                                        </button>
                                        <br />
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        );
    }
};

export default Blogs;
