import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import { IoClose } from "react-icons/io5";
import '../assets/css/navigation/navbar.css';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Logo from "../assets/images/oriphal1.png"
import { Link } from 'react-router-dom';
import { scrollToTop } from '../utils/scrollToTop';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const location = useLocation();
  const navbarRef = useRef(null);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <nav className={`navbar ${isNavOpen ? 'nav-open' : ''}`} ref={navbarRef}>
      <div className="navbar-logo">
        <img src={Logo} className="head-img-1" alt={"logo"} />
      </div>

      <div className="hamburger-icon" >
        {/* <FontAwesomeIcon icon={isNavOpen ? faTimes : faBars} />*/}
        <input type="checkbox" id="menu_checkbox" onClick={toggleNav}/>
          <label for="menu_checkbox">
            <div></div>
            <div></div>
            <div></div>
          </label>
      </div>


      <div className= {`nav-links ${isNavOpen ? 'open' : ''}`} >
        <ul className="navbar-menu">
          <li className={location.pathname === '/' ? 'active' : ''}>
            <Link className="linknb" to="/" onClick={() => {scrollToTop(); closeNav();}} >Home</Link>
          </li>

          <li className={location.pathname === '/about' ? 'active' : ''}>
            <div className="Down-main">
              <Link className="linknb" to='/about' onClick={() => {scrollToTop(); closeNav();}}>About Us</Link>
              <FaChevronDown className='Down-arrow' style={{ marginLeft: '-12px', fontSize: '0.8em' }} />
            </div>

            {/* Add the dropdown content for "About Us" */}
            <div className="dropdown-content">
              <li className={location.pathname === '/mission-vision' ? 'active' : ''}>
                <Link className="linknb" to="/mission-vision" onClick={() => {scrollToTop(); closeNav();}}>Mission & Vision</Link>
              </li>
              <hr />
             {/* <li className={location.pathname === '/team' ? 'active' : ''}>
                <Link className="linknb" to="/team" onClick={scrollToTop}>Our Team</Link>
             </li>*/}
              <hr />
              <li className={location.pathname === '/our-story' ? 'active' : ''}>
                <Link className="linknb" to="/our-story" onClick={() => {scrollToTop(); closeNav();}}>Our Story</Link>
              </li>
            </div>
          </li>

          <li >
            <div className="Down-main">
             <Link className="linknb" >Product</Link>
             <FaChevronDown className='Down-arrow' style={{ marginLeft: '-12px', fontSize: '0.8em' }} />
            </div>

            <div className="dropdown-content">
              <li className={location.pathname === '/product' ? 'active' : ''}>
                <Link className="linknb" to="/product" onClick={() => {scrollToTop(); closeNav();}}>Fresh Fruit</Link>
              </li>
              <hr />
              <li className={location.pathname === '/product1' ? 'active' : ''}>
                <Link className="linknb" to="/product1" onClick={() => {scrollToTop(); closeNav();}}>Pulp</Link>
              </li>
            </div>
          </li>

          <li className={location.pathname === '/whyus' ? 'active' : ''}>
            <Link className="linknb" to="/whyus" onClick={() => {scrollToTop(); closeNav();}}>Why Us</Link>
          </li>

          {/* <li className={location.pathname === '/blogs' ? 'active' : ''}>
            <Link className="linknb" to="/blogs" onClick={() => {scrollToTop(); closeNav();}}>Blogs</Link>
          </li> */}


          <li className={location.pathname === '/contact' ? 'active' : ''}>
            <Link className="linknb" to="/contact" onClick={() => {scrollToTop(); closeNav();}}>Contact Us</Link>
          </li>
        </ul>
       

      </div>
    </nav>
  );
}

export default Navbar;