import React, { useState } from 'react';
import { Resizable } from 'react-resizable';
import imge from '../assets/images/Contact-Page/contact.jpg'
import '../assets/css/comman/contactForm.css';
import {FETCH} from '../services/Fetch.tsx'
const ResizableTextArea = ({ onResize, width, height, ...props }) => (
    <Resizable
        width={width}
        height={height}
        onResize={onResize}
        draggableOpts={{ enableUserSelectHack: false }}
    >
        <textarea {...props} />
    </Resizable>
);

const ContactForm = () => {

    const handleResize = (e, { size }) => {
        // Handle resizing of the textarea
        // You can update the state or perform any other actions here
        // console.log('Resized to:', size);
    };
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
    });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    // console.log('Form submitted:', formData);

    try {
      let {data, status} = await FETCH(
        'post',
        '/contact-request',
        '',
        formData
      )
      alert(data.message)
    } catch (error) {
    //   console.log('ERROR ',error)
      alert('CHeck Your Internet COnnection')
    }

  };


    return (
        <div className="contact-component-container">
        <div className="contact-form-container">
        <form className="contact-form">
            <div className="form-line">
                <div className='label'>
                    First Name:
                    <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        className="line-input"
                    />
                </div>
                <div className='label'>
                    Last Name:
                    <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        className="line-input"
                    />
                </div>
            </div>
            <div className="form-line">
                <div className='label'>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="line-input"
                    />
                </div>
            </div>
            <div className="form-line">
                <div className='label'>
                    Phone Number:
                    <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        className="line-input"
                    />
                </div>
            </div>
            <div className="form-line resizable-textarea">
                <div className='label'>
                    Message:
                    <ResizableTextArea
                        name="message"
                        value={formData.message}
                        onResize={handleResize}
                        width={200} // Initial width
                        height={100} // Initial height
                        onChange={handleChange}
                        className="line-input"
                    />
                </div>
            </div>
            <button type="button" class="slide-btn label" onClick={handleSubmit}>
            Submit
          </button>
        </form>
        </div>
        <div className="image-container" data-aos="fade-up">
        {/* Replace 'your-image-path.jpg' with the actual path to your image */}
        <img src={imge} alt="Your Image" className="form-image" />
      </div>
        </div>
    );
};

export default ContactForm;
