import React, { useEffect, useState } from 'react'
import contact from '../../assets/images/Contact-Page/Contactpage-Slider.jpg'
import imge from '../../assets/images/Contact-Page/ContactFruit-img.png'
import "../../assets/css/containers/contact.css"
import TextiputA from "../../atoms/TextinputA"
import TextiputB from "../../atoms/TextinputB"
import ButtonA from "../../atoms/ButtonA"
import { BsGeoAlt, BsEnvelope } from "react-icons/bs";
import { BiPhoneCall } from "react-icons/bi";
import ContactForm from '../../common/ContactForm'
import Loader from '../../screen/Loader'

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setemail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');
  const [showPageLoader, setShowPageLoader] = useState(true);


  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else{


  return (
    <section className="section mt-5 pt-4 pb-5 contact-div">
      <div className="contact-container">
        <div className="contact-img-fluid about-img-fluid">
          <h2 data-aos="fade-up">CONTACT US</h2>
        </div>

        <div className="contact-Main-m3">
          <div className="contact-col-md-6  contactCSS" data-aos="fade-right">
            <h2 className="section-heading">Contact US</h2>
            <hr className="hrCSS" />
            <p className="contact-t1">Questions, comments, or suggestion? Simply fill in the <br />form and we'll be in touch shortly.</p>
          </div>

          <div className="contact-from-group-main1">
            <ContactForm />
          </div>

        </div>

        <div className="contact-box-M2 bg-section-color">
          <div className="contact-box-b1 bl-1" data-aos="fade-right">
            <BsGeoAlt className="contact-icon-m1" />
            <h1>Location</h1>
            <p className="contact-t2">Chaitanya Mega Market Indapur, Pune 413106.</p>
          </div>

          <div className="contact-box-b1 bl-2" data-aos="fade-up">
            <BiPhoneCall className="contact-icon-m1" />
            <h1>Phone Number</h1>
            <p className="contact-t2">+91 7066969991 </p>
          </div>

          <div className="contact-box-b1 bl-3" data-aos="fade-left">
            <BsEnvelope className="contact-icon-m1 l1" />
            <h1>Email</h1>
            <p className="contact-t2">info@oriphal.com<br/>contact@oriphal.com</p>
          </div>
        </div>

        <div className="contact-box-map mp-1">
          <iframe className="contact-google-m-2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117127.64428481032!2d74.9337359376526!3d18.115493682571433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc47900318d6ab7%3A0x128cd902a6c98280!2sChaitnya%20Mega%20Market.Chandane%20Devlopers!5e0!3m2!1sen!2sin!4v1709375362444!5m2!1sen!2sin" ></iframe>
        </div>
      </div>
    </section>
  )
  }
}

export default Contact