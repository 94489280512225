import React, { useEffect, useState } from "react";
import Loader from "../../screen/Loader";
import "../../assets/css/includes/pulpsinfo.css";
import pomepulps_img from "../../assets/images/Products/mango_pulp_img.png";

const Mangopulp = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else {
    return (
      <div className="pulpstinfo-container-main">
        <div className="pulpsinfo-container">
          <div className="pulpsinfo-text-main">
            <p className="pulpsinfo-heder">MANGO PULP </p>
            <div className="pulpsinfo-line" />
            <div className="pulpsinfo-paregraph">
              <div className="pulpsinfo-content-main">
                <div className="pulpsinfo-content-main-text">
                  <p className="pulpsinfo-paregraph-text1"> Product Details:</p>
                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Type:</span>
                    <p className="pulpsinfo-pr-text">Mango Pulp</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Part:</span>
                    <p className="pulpsinfo-pr-text">Peeled</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Color:</span>
                    <p className="pulpsinfo-pr-text">Orange</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Taste:</span>
                    <p className="pulpsinfo-pr-text">Sweet</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">
                      Freezing Process:
                    </span>
                    <p className="pulpsinfo-pr-text"> BQF</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Shelf Life: </span>
                    <p className="pulpsinfo-pr-text"> 18 Months</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Features:</span>
                    <p className="pulpsinfo-pr-text ">
                      1) Extracted from Fresh and matured Mango
                      <br />
                      2) Original Taste & flavor
                      <br />
                      3) Availability around the year
                    </p>
                  </div>
                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">
                      Product Description:{" "}
                    </span>
                    <p className="pulpsinfo-pr-text text-p1">
                      Alphonso Mango Pulp - An emperor among kings, this variety
                      of mango is globally acclaimed as the "king of mangoes".
                      Aromatic and special, our Alphonso Mango Pulp is prepared
                      from the world known "Ratnagiri & Deogarh Alphonso".
                      <br />
                      Kesar Mango Pulp - If there is any variety of Mangos which
                      can even begin to challenge the charm of Alphonso - it is
                      this! A very distinctive flavor and an allure which
                      surrounds this variety lends it to several uses - from
                      exotic ice-cream flavors to medicines. Who knows, this
                      probably is the flavor which sets a few mango drinks apart
                      from "just-another-mango-drink"
                    </p>
                  </div>
                </div>

                <img src={pomepulps_img} className="pulpspome_img1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Mangopulp;
