import React, {useState,useEffect}from 'react'
import '../assets/css/screen/screen.css'
import Navbar from '../navigation/Navbar'
import Footer from '../navigation/footernew.js'

const Screen = (props) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // Event listener to update width and height on window resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const style = {
    '--window-width': `${windowWidth}px`,
    '--window-height': `${windowHeight}px`,
    overflow : 'hidden' 
  };

  return (
    <div style={style}>
      <div>
        <Navbar />
      </div>
      <div className='main-Page'>
        {props?.children}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  )
}

export default Screen