import React, { useEffect, useState } from 'react'
import '../../assets/css/containers/product.css'

import productl1 from '../../assets/images/product_img-1.jpg';
import FruitProduct from '../../common/FruitProduct';

import pulpproduct1 from '../../assets/images/Products/pomegranate_pulp_img.png';
import pulpproduct2 from '../../assets/images/Products/guava_pulp_img.png';
import pulpproduct3 from '../../assets/images/Products/guava_pulp_img.png';
import pulpproduct4 from '../../assets/images/Products/mango_pulp_img.png';
import pulpproduct5 from '../../assets/images/Products/guava_pulp_img.png';
import { useNavigate } from 'react-router-dom';
import Loader from '../../screen/Loader';
import { scrollToTop } from '../../utils/scrollToTop'
const Product = () => {
  const navigate = useNavigate();
  const [showPageLoader , setShowPageLoader] = useState(true)

  const navigateToPomegranatepulps = () => {
    navigate('/Pomegranatepulps');
    scrollToTop();
  };


  const navigateToBananapulpus = () => {
    navigate('/bananapulps');
    scrollToTop();
  };

  const navigateToCusterdpulpus = () => {
    navigate('/custerdapplepulp');
    scrollToTop();
  };
  const navigateToMangopulpus = () => {
    navigate('/Mangopulps');
    scrollToTop();
  };
  const navigateToGuavapulpus = () => {
    navigate('/guavapulp');
    scrollToTop();
  };

  useEffect(()=>{
    setTimeout(()=>{setShowPageLoader(false)},1650)
  },[])

  if(showPageLoader){
    return <Loader />
  }else{
  return (
    <section className="product-section-mt-5 product-pt-4 product-pb-5 product-div">
      <div className="product-container">

        <div className="products-img-fluid about-img-fluid">
          <h2 style={{ marginTop: -50 }} data-aos="fade-up">PULPS</h2>
        </div>

       
        
        <div className="product-Fruit-container-main  bg-section-color">

          <p className="product-Fruit-heding-text section-heading">Pulp</p>

          <div className="product-Fruit-main">

            

            <div className="product-Fruit-text1">
              <p className="product-Fruit-text-p1 at">Fruit pulp is the delectable, juicy essence concealed within the protective embrace of a fruit's skin. Comprising the soft, fleshy part of the fruit that surrounds the seeds, if present, pulp is a reservoir of natural goodness. Bursting with vitamins, minerals,
               and succulent sweetness, it offers a sensory delight with every bite. Whether enjoyed fresh as part of the whole fruit or harnessed for culinary endeavors, fruit pulp is a versatile ingredient.

              </p>
            </div>
             {/*<img src={productl1} className="product-Fruit-img-1" alt={"logo"} />*/}
          </div>

          <div className="product-Fruitf1-main">
            <FruitProduct
              title="Pomegranate"
              image={pulpproduct1}
              handleClick={() => navigateToPomegranatepulps()}
              description="Fresh Pulp Perfection Freshness Captured in Every Spoonful!"
              additionalClassName="my-custom-class1"
              boxClassName="friutp1"
            />
            <FruitProduct
              title="Banana"
              image={pulpproduct2}
             
              handleClick={() => navigateToBananapulpus()}
              description="Fresh Pulp Perfection Freshness Captured in Every Spoonful!"
              additionalClassName="my-custom-class2"
              boxClassName="friutp2"
            />
            <FruitProduct
              title="Custerd apple"
              image={pulpproduct3}
             
              handleClick={() => navigateToCusterdpulpus()}
              description="Fresh Pulp Perfection Freshness Captured in Every Spoonful!"
              additionalClassName="my-custom-class3"
              boxClassName="friutp3"
            />
          </div>
          <div className="product-Fruitf1-main1">
            <FruitProduct
              title="Mango"
              image={pulpproduct4}
             
              handleClick={() => navigateToMangopulpus()}
              description="Fresh Pulp Perfection Freshness Captured in Every Spoonful!"
              additionalClassName="my-custom-class4"
              boxClassName="friutp4"
            />
            <FruitProduct
              title="Guava"
              image={pulpproduct5}
              
              handleClick={() => navigateToGuavapulpus()}
              description="Fresh Pulp Perfection Freshness Captured in Every Spoonful!"
              additionalClassName="my-custom-class5"
              boxClassName="friutp5"
            />

          </div>

        </div>
      </div>
    </section>
  )
}
}

export default Product