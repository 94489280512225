import React, { useEffect, useState } from "react";
import contact from "../../assets/images/Contact-Page/Contactpage-Slider.jpg";
import "../../assets/css/containers/whyus.css";
import why_1 from "../../assets/images/why_img-1.jpg";
import why_2 from "../../assets/images/why_img-2.jpg";
import why_3 from "../../assets/images/why_img-3.jpg";
import why_logo1 from "../../assets/images/logo/log_wy-3.png";
import why_logo2 from "../../assets/images/logo/log_wy-2.png";
import why_logo3 from "../../assets/images/logo/log_wy-1.png";
import why_trac1 from "../../assets/images/Etraceblity1.png";
import why_trac2 from "../../assets/images/Etraceblity2.png";
import why_trac3 from "../../assets/images/Etraceblity3.png";
import why_trac4 from "../../assets/images/Etraceblity4.png";
import trace from "../../assets/images/tracebility.jpg";
import Loader from "../../screen/Loader";
const WhyUs = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else {
    return (
      <section className="-WhyUs-section =mt-5 pt-4 pb-5 WhyUs-div">
        <div className="WhyUs-container">
          <div className="whyus-img-fluid about-img-fluid">
            <h2 style={{ marginTop: -50 }} data-aos="fade-up">
              Why Us ?
            </h2>
          </div>

          <div className="WhyUs-main-1 bg-section-color">
            <div className="WhyUs-box-1 why-us-box-bg1">
              <img src={why_logo1} className="WhyUs-log1" alt={"logo"} />
              <p className="WhyUs-heding1 section-inner-head">
                Quality Standards
              </p>
              <p className="WhyUs-text-1 at">
                We hand-select our fruits, vegetables, and anything else we
                source. Each item is produced and procured with a high standard
                of ingredients and humane practices from partners we trust. It's
                a true quality that you can taste.
              </p>
            </div>
            <img src={why_1} className="WhyUs-pic1" alt={"logo"} />
          </div>

          <div className="WhyUs-main-1 whyus-dirction">
            <div className="WhyUs-box-1 why-us-box-bg2">
              <img src={why_logo2} className="WhyUs-log1" alt={"logo"} />
              <p className="WhyUs-heding1 section-inner-head">
                Health & Safety Rules
              </p>
              <p className="WhyUs-text-1 at">
                All our representatives compulsorily wear hand gloves and
                hairnet while handling food. The store and all fixtures are
                sanitized at regular intervals. Food storage and display areas
                are cleaned with food-grade cleaners per prefixed schedules.
              </p>
            </div>
            <img src={why_2} className="WhyUs-pic1" alt={"logo"} />
          </div>

          <div className="WhyUs-main-1 bg-section-color">
            <div className="WhyUs-box-1 why-us-box-bg3">
              <img src={why_logo3} className="WhyUs-log1" alt={"logo"} />
              <p className="WhyUs-heding1 section-inner-head">Values</p>
              <p className="WhyUs-text-1 at">
                We are dedicated to delivering exceptional value to our
                customers. While we don't assert to be the most inexpensive
                option in the market, we assure you that comparable quality
                cannot be found at a lower price. Our commitment extends beyond
                mere claims, as we abstain from the practice of presenting
                counterfeit discounts or deceptive offers. Instead, our
                promotions and discounts are thoughtfully crafted to acknowledge
                and reward individuals who prioritize both quality and value.
              </p>
            </div>
            <img src={why_3} className="WhyUs-pic1" alt={"logo"} />
          </div>

          <div className="WhyUs-traceability-main">
            <div className="WhyUs-traceability-textmain-1">
              <p className="WhyUs-traceability-heding section-heading">
                Importance of Traceability in Farming
              </p>
              <p className="WhyUs-traceability-text-2 at">
                Consumers worldwide are today more demanding, health and
                safety-conscious and increasingly aware of food safety
                standards. They want to know in real time where their food comes
                from. The way the food supply chain is currently designed and
                managed makes it challenging for the food producers, retailers
                and consumers to confirm the origin or source of the fresh
                produce. Besides, there is a constant threat of adulteration and
                food fraud.
              </p>
              <p className="WhyUs-traceability-text-3 at">
                We, at Oriphal, believe that everyone deserves to know where the
                food they eat came from and how it was produced. Our consumers
                can now locate the farm and field on a map, access details of
                the farmer who has grown the produce, get details on the
                pesticides and fertilizers used and also know more about the
                crop variety.
              </p>
              <p className="WhyUs-traceability-text-4 at">
                This unprecedented level of transparency helps us maintain a
                strong and lasting relationship with our customers. It gives
                them adequate confidence about the safety of oriphal's produce
                and in the promise that all our food is 100% safe for
                consumption.
              </p>
            </div>

            <div className="">
              <div className="trace-img">
                <img src={trace} alt="" className="trace" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default WhyUs;
