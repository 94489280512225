import React from 'react';
import '../assets/css/comman/fruitproduct.css';
import Logo1 from "../assets/images/oriphal1.png"
const FruitProduct = (props) => {
    const { title, image, navigate, additionalClassName, boxClassName, description ,handleClick, } = props;
    return (
        <div className={`Fruits-Product-main ${additionalClassName}`}>

            <div className={`Fruits-Product-box ${boxClassName}`}  >

                <img src={Logo1} className="product-heder-img" alt={"logo"} />
                {/*<p className="Fruits-Product-heder">{title}</p>*/}
                <img src={image} className="product-Fruits-img1" alt={title} />
                {/* <p className="product-Fruits-text1">{description}</p> */}
                <button type="button" class="" onClick={handleClick} style={{

                }}>
                <i class="fa-solid fa-arrow-right fa-beat" style={{color: `#007946`,height:`20px`,width:`40px`}}></i>
                </button>
            </div>

        </div>
    );
}

export default FruitProduct;
