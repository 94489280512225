import React, { useEffect, useState } from 'react'
import '../../assets/css/includes/pulpsinfo.css';
import pomepulps_img from '../../assets/images/Products/guava_pulp_img.png';
import Loader from '../../screen/Loader';


const Guavapulp = () => {

  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else{

  return (
    <div className="pulpstinfo-container-main">
      <div className="pulpsinfo-container">
        <div className='pulpsinfo-text-main'>
          <p className='pulpsinfo-heder'>GUAVA PULP </p>
          <div className='pulpsinfo-line' />
          <div className='pulpsinfo-paregraph'>


            <div className='pulpsinfo-content-main'>
              <div className='pulpsinfo-content-main-text'>
              <p className='pulpsinfo-paregraph-text1'> Product Details:</p>
                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Type:</span>
                  <p className='pulpsinfo-pr-text'>Guava Pulp
                  </p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Part:</span>
                  <p className='pulpsinfo-pr-text'>Peeled</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Color:</span>
                  <p className='pulpsinfo-pr-text'>Cream</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Taste:</span>
                  <p className='pulpsinfo-pr-text'>Sweet</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Freezing Process:</span>
                  <p className='pulpsinfo-pr-text'> BQF</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Shelf Life: </span>
                  <p className='pulpsinfo-pr-text'> 24 Months</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Features:</span>
                  <p className='pulpsinfo-pr-text '>
                    1)Extracted from Fresh Guava
                    <br />
                    2) Original Taste & flavor
                    <br />
                    3) Availability around the year
                  </p>
                </div>
                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Product Description: </span>
                  <p className='pulpsinfo-pr-text text-p1'>The Guava (Psidium guajava) is a round or pear-shaped tropical fruit with a white, yellow, or pink interior, which is fleshy and interspersed with many hard seeds.
                    It has the highest Vitamin C contents among fruits and its delicious juice is widely appreciated for this vitamin.
                    It also contains a considerable amount of pectin.

                    It would be difficult to find any place in India where the Guava tree does not flourish naturally. The fruit grows on relatively small,
                    narrow trunked trees which are characterised by an almost total absence of bark. The product Guava Pulp is obtained by the commercial processing of sound,
                    mature Guava fruit by passing through a 0.7mm sieve
                  </p>
                </div>

              </div>

              <img src={pomepulps_img} className='pulpspome_img1' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  }
}

export default Guavapulp