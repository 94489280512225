import React, { useEffect, useState } from "react";
import "../../assets/css/includes/productinfo/pomegranate.css";
import Loader from "../../screen/Loader";
import pome_img from "../../assets/images/Products/pomegranate_img.png";
import pome_img1 from "../../assets/images/Products/pomo1.jpg";
import pome_img2 from "../../assets/images/Products/pomo2.jpg";
import pome_img3 from "../../assets/images/Products/pomo3.jpg";
import pome_img4 from "../../assets/images/Products/pomo4.jpg";
import pome_img5 from "../../assets/images/Products/pomo5.jpg";
import pome_img6 from "../../assets/images/Products/pomo6.jpg";
const Pomegranate = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else{
    return (
      <div className="productinfo-container-main">
        <div className="productinfo-container">
          <div className="productinfo-text-main">
            <p className="productinfo-heder">POMEGRANATE FRUITS</p>
            <div className="productinfo-line" />
            <div className="productinfo-paregraph">
              <div className="productinfo-content-main">
                <div className="productinfo-content-main-text">
                  <p className="productinfo-paregraph-text1">
                    Fresh Pomegranate is one of the important fruit crops
                    commercially grown in the state of Maharashtra. The main
                    commercially grown varieties are Bhagwa, Arkata & Ganesh.
                  </p>
                  <br />
                  <p className="productinfo-paregraph-text1">
                    {" "}
                    Bhagwa is widely known for its soft seed, Dark red color,
                    and extremely delicious.
                  </p>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Major Export Variety:
                    </span>
                    <p className="productinfo-pr-text">Bhagwa.</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Packaging:</span>
                    <p className="productinfo-pr-text">
                      {" "}
                      Net weight of box 3.50/4.00kg, 4.50kg, 15-20kg in plastic
                      carets.
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Fruit count/Box (3.50 kg net wt.):
                    </span>
                    <p className="productinfo-pr-text no-text1">
                      1) 200 to 240 grm-16 Count
                      <br />
                      2) 240 to 275 grm-14 Count
                      <br />
                      3) 275 to 325 grm-12 Count
                      <br />
                      4) 325 Above-9/10 Count
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Details:</span>
                    <p className="productinfo-pr-text">
                      Minimum Weight 180gm, maximum weight 400gm
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Colour of arils:
                    </span>
                    <p className="productinfo-pr-text"> Dark Cherry red.</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">T.S.S.: </span>
                    <p className="productinfo-pr-text"> 15 to 16</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Acidity: </span>
                    <p className="productinfo-pr-text"> 0.35-0.39%</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Taste: </span>
                    <p className="productinfo-pr-text">Sweet</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Availability: </span>
                    <p className="productinfo-pr-text">
                      January February March April July August | Nov | Dec
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Health Benifits:{" "}
                    </span>
                    <p className="productinfo-pr-text text-p1">
                      Pomegranates are one of the best fruits out there that can
                      make you healthy. They are juicy red fruits that are
                      sweet. In recent times we have discovered various health
                      benefits of pomegranates, and today we will share some of
                      them here with you all. The pomegranate is a sweet, tart
                      fruit with thick, red skin. While the skin is not edible,
                      it holds hundreds of juicy seeds that you can eat plain or
                      you can simply add them to your breakfast, salad, or
                      oatmeal. Pomegranate juice is also very healthy and
                      nutritious. Everyone who does not like eating fruit as a
                      whole can always drink fresh Pomegranate juiceVarious
                      brands sell packed pomegranate juice, we would always
                      advise you to drink freshly made juice packed juice often
                      comes with added sugar and preservatives. Pomegranates
                      have various health benefits which we will learn about in
                      the next bit.
                    </p>
                  </div>
                </div>
                <img src={pome_img} className="pome_img1" />
              </div>

              <div className="productinfo-line1" />

              <div className="productinfo-main-images1">
                <div className="productinfo-content-main1">
                  <img src={pome_img1} className="pome_img2" />
                  <img src={pome_img2} className="pome_img2" />
                  <img src={pome_img3} className="pome_img2" />
                  <img src={pome_img4} className="pome_img2" />
                  <img src={pome_img5} className="pome_img2" />
                  <img src={pome_img6} className="pome_img2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Pomegranate;
