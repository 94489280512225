import React, { useEffect, useState } from 'react';
import '../../assets/css/includes/blogdetail.css'
import Loader from '../../screen/Loader';
import dban_img from "../../assets/images/Slider1.jpg";
import d_img1 from "../../assets/images/footer.jpg";
import d_img2 from "../../assets/images/footer.png";

const Blog_detail = () => {
    const [showPageLoader, setShowPageLoader] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        setShowPageLoader(false);
      }, 1650);
    }, []);
  
    if (showPageLoader) {
      return <Loader />;
    } else{
  return (
    <div className="blogdetail-container-main">
    <div className="blogdetail-container">
      <div className="blogdetail-text-main">
        
        <div className="blogdetail-paregraph">
          <div className="blogdetail-content-main">
           <img src={dban_img} className="detail_img1" /> 
           <p className="blogdetail-heder">Pomegranate harvesting method for farmer's</p>
            <div className="blogdetail-line" />
            <div className="blogdetail-content-main-text">
              <p className="blogdetail-paregraph-text1">
              Pomegranate harvesting requires careful timing and technique to ensure optimal fruit quality and yield. 
              Typically, pomegranates are ready for harvest in the late summer to early fall months, depending on the 
              and growing conditions. One key indicator of ripeness is the fruit's color, which should be a deep, vibrant
              shade of red or orange, depending on the variety. Additionally, the fruit's outer skin should feel firm 
              and have a glossy appearance. Harvesting is usually done by hand, using pruning shears or clippers to 
              cut the fruit from the tree's branches, ensuring minimal damage to the fruit and tree.
              </p>
              <br />
              <p className="blogdetail-paregraph-text1">
                {" "}
                After harvesting, it's essential to handle the pomegranates with care to prevent bruising or other damage.
                 The fruit should be stored in a cool, dry place with good ventilation to maintain freshness and prolong shelf life.
                  Proper post-harvest handling practices, such as sorting and grading, help ensure that only high-quality fruit
                   reaches the market. By paying close attention to timing and employing gentle harvesting and handling techniques, 
                   growers can optimize the quality and marketability of their pomegranate harvests.
              </p>
              <div className="blogdetail-main-images1">
              <div className="blogdetail-content-main1">
                 <img src={d_img1} className="detail_img2 " />
              </div>
            </div>


              <div className="blogdetail-texts1">
                <span className="blogdetail-snp-text">
                  Market Coverage:
                </span>
                <p className="blogdetail-pr-text">
                Improving market coverage in agriculture demands a multifaceted strategy. Initially, thorough market research is imperative to discern consumer preferences and behaviors.
                 Diversification across various distribution channels, including farmer's markets, CSA programs, online platforms, grocery stores, and restaurants, broadens access to potential buyers.
                 Value-added product creation, such as jams or sauces, enhances product appeal and attracts consumers seeking unique offerings. Effective branding and packaging amplify 
                 visibility and convey product quality. Direct marketing avenues like farmers' markets foster personal connections with consumers, while online sales extend reach beyond local confines. 
                 Collaborating with fellow farmers and local businesses expands market presence, while prioritizing exceptional customer service cultivates loyalty and drives sustained growth in market coverage.
                </p>
              </div>

              

              <div className="blogdetail-texts1">
                <span className="blogdetail-snp-text">
                 Agricultural Advice:
                </span>
                <p className="blogdetail-pr-text no-text1">
                Depending on your climate, soil type, and market demand, certain crops may be more suitable for your area than others.
                 Researching and selecting the right crops can significantly impact your agricultural success.
                </p>
              </div>
              
            <div className="blogdetail-main-images1">
              <div className="blogdetail-content-main1">
                 <img src={d_img2} className="detail_img2 " />
              </div>
            </div>

              <div className="blogdetail-texts1">
                <span className="blogdetail-snp-text">
                  Our Product:{" "}
                </span>
                <p className="blogdetail-pr-text text-p1">
                  Pomegranates are one of the best fruits out there that can
                  make you healthy. They are juicy red fruits that are
                  sweet. In recent times we have discovered various health
                  benefits of pomegranates, and today we will share some of
                  them here with you all. The pomegranate is a sweet, tart
                  fruit with thick, red skin. While the skin is not edible,
                  it holds hundreds of juicy seeds that you can eat plain or
                  you can simply add them to your breakfast, salad, or
                  oatmeal. Pomegranate juice is also very healthy and
                  nutritious. Everyone who does not like eating fruit as a
                  whole can always drink fresh Pomegranate juiceVarious
                  brands sell packed pomegranate juice, we would always
                  advise you to drink freshly made juice packed juice often
                  comes with added sugar and preservatives. Pomegranates
                  have various health benefits which we will learn about in
                  the next bit.
                </p>
              </div>
            </div>
           
          </div>

          <div className="blogdetail-line1" />

        </div>
      </div>
    </div>
  </div>
  )
 }
}

export default Blog_detail
