import React, { useEffect, useState } from "react";
import "../../assets/css/includes/pulpsinfo.css";
import pomepulps_img from "../../assets/images/Products/banana_pulp_img.png";
import Loader from "../../screen/Loader";

const Bananapulps = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else {
    return (
      <div className="pulpstinfo-container-main">
        <div className="pulpsinfo-container">
          <div className="pulpsinfo-text-main">
            <p className="pulpsinfo-heder">BANANA PULP </p>
            <div className="pulpsinfo-line" />
            <div className="pulpsinfo-paregraph">
              <div className="pulpsinfo-content-main">
                <div className="pulpsinfo-content-main-text">
                  <p className="pulpsinfo-paregraph-text1"> Product Details:</p>
                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Type:</span>
                    <p className="pulpsinfo-pr-text"> Banana Pulp</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Part:</span>
                    <p className="pulpsinfo-pr-text">Peeled</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Color:</span>
                    <p className="pulpsinfo-pr-text">Cream</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Taste:</span>
                    <p className="pulpsinfo-pr-text">
                      Sweet Characteristic ripe banana
                    </p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">
                      Freezing Process:
                    </span>
                    <p className="pulpsinfo-pr-text"> BQF</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Shelf Life: </span>
                    <p className="pulpsinfo-pr-text"> 24 Months</p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">Features:</span>
                    <p className="pulpsinfo-pr-text ">
                      1) Extracted from Fresh Banana
                      <br />
                      2) Original Taste & flavor
                      <br />
                      3) Availability around the year
                    </p>
                  </div>

                  <div className="pulpsinfo-texts1">
                    <span className="pulpsinfo-snp-text">
                      Product Description:{" "}
                    </span>
                    <p className="pulpsinfo-pr-text text-p1">
                      Banana Puree is packed aseptically in pre-sterilized
                      aseptic bags placed with poly liner in a food-grade
                      epoxy-painted (inside) open-top MS drum. Banana Puree
                      should be stored at ambient temperature and not below 40C.
                      Avoid exposure to higher temperatures and sunlight.
                    </p>
                  </div>
                </div>

                <img src={pomepulps_img} className="pulpspome_img1" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Bananapulps;
