import React, { useEffect, useState } from "react";
import "../../assets/css/includes/productinfo/pomegranate.css";
import Loader from "../../screen/Loader";
import custard_img from "../../assets/images/Products/Custard-Apple_img.png";
import custard_img1 from "../../assets/images/Products/cappel1.jpg";
import custard_img2 from "../../assets/images/Products/cappel2.jpg";
import custard_img3 from "../../assets/images/Products/cappel3.jpg";
import custard_img4 from "../../assets/images/Products/cappel4.jpg";
import custard_img5 from "../../assets/images/Products/cappel5.jpg";
import custard_img6 from "../../assets/images/Products/cappel6.jpg";
const Custerdapple = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else {
    return (
      <div className="productinfo-container-main">
        <div className="productinfo-container">
          <div className="productinfo-text-main">
            <p className="productinfo-heder">CUSTERD APPLE FRUITS</p>
            <div className="productinfo-line" />
            <div className="productinfo-paregraph">
              <div className="productinfo-content-main">
                <div className="productinfo-content-main-text">
                  <p className="productinfo-paregraph-text1">
                    Fresh Custard apple is one of the important fruit crops
                    commercially grown in the state of Maharashtra. The main
                    commercially grown varieties are NMK-01 (Golden) and Red
                    Sitaphal, Balanagari, Washington and Purandhar.
                  </p>
                  <br />
                  <p className="productinfo-paregraph-text1">
                    {" "}
                    NMK-01 (Golden) Green Custard apple.
                  </p>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Major Export Variety:
                    </span>
                    <p className="productinfo-pr-text">
                      NMK-01 (Golden) Green Custard apple.
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Packaging:</span>
                    <p className="productinfo-pr-text">
                      {" "}
                      Net weight of box 10 Kg in plastic carets.
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Fruit count/Box (3.50 kg net wt.):
                    </span>
                    <p className="productinfo-pr-text no-text1">
                      1) 200 to 240 grm-2 Count
                      <br />
                      2) 240 to 275 grm-3 Count
                      <br />
                      3) 275 to 325 grm-5 Count
                      <br />
                      4) 325 Above-8/12 Count
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Details:</span>
                    <p className="productinfo-pr-text">
                      Minimum Weight 105gm, maximum weight 400gm
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Colour of arils:
                    </span>
                    <p className="productinfo-pr-text"> Green.</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">T.S.S.: </span>
                    <p className="productinfo-pr-text"> 19.37 to 28.53 0B</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Acidity: </span>
                    <p className="productinfo-pr-text"> 0.18-0.34</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Taste: </span>
                    <p className="productinfo-pr-text">Sweet</p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Availability: </span>
                    <p className="productinfo-pr-text">August and November.</p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Health Benifits:{" "}
                    </span>
                    <p className="productinfo-pr-text">
                      Custard apples, belonging to the Annonaceae family, are
                      nutrient-rich fruits offering various health benefits.
                      Packed with essential nutrients like vitamin C, B6, and
                      potassium, they contribute to heart health, bone strength,
                      and immune function. The fruit's antioxidants combat
                      oxidative stress, potentially reducing the risk of chronic
                      diseases. With a high fiber content, custard apples
                      support digestion and a healthy gut microbiome. Their
                      potential anti-inflammatory and anti-cancer properties
                      make them a valuable addition to a balanced diet.
                    </p>
                  </div>
                </div>
                <img src={custard_img} className="pome_img1" />
              </div>

              <div className="productinfo-line1" />
              <div className="productinfo-main-images1">
                <div className="productinfo-content-main1">
                  <img src={custard_img1} className="pome_img2" />
                  <img src={custard_img2} className="pome_img2" />
                  <img src={custard_img3} className="pome_img2" />
                  <img src={custard_img4} className="pome_img2" />
                  <img src={custard_img5} className="pome_img2" />
                  <img src={custard_img6} className="pome_img2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Custerdapple;
