import React,{useState,useEffect} from 'react'
import '../../assets/css/containers/about.css'
import ListItem from '../../atoms/ListItem';
import about from '../../assets/images/Pages-slider/About.jpg'
import ioc4 from '../../assets/images/pics/World.png';
import ioc5 from '../../assets/images/pics/icon4.png';
import ioc6 from '../../assets/images/pics/Delevery.png';
import pvi1 from '../../assets/images/pics/fruitsimg.jpg';
import pvi2 from '../../assets/images/pics/pulpimg.jpg';
import Cft1 from '../../assets/images/teams/certificate1.jpg';
import Cft2 from '../../assets/images/teams/certicate2.jpg';
import Cft3 from '../../assets/images/teams/certificate3.jpg';
import Loader from '../../screen/Loader';
// import Cft4 from '../../assets/images/pics/pulpimg.jpg'

const About = () => {

  const [index, setIndex] = useState(0)
  const [showPageLoader , setShowPageLoader] = useState(true)


  const certArr = [{ image: Cft1, text: 'Unique Assessment System LLC Cerificate' }, { image: Cft2, text: 'Quality Research Organization Cerificate' }, { image: Cft3, text: 'Global G.A.P Cerificate' }]

  const handleLeft = () => {
    if (index > 0) {
      setIndex(index - 1)
    }
  }
  const handleRight = () => {
    if (index < certArr.length - 1) {
      setIndex(index + 1)
    }
  }

  useEffect(()=>{
    setTimeout(()=>{setShowPageLoader(false)},1650)
  },[])

  if(showPageLoader){
    return <Loader />
  }else{
  return (
    <section className='about'>

      <div className="about-img-fluid">
        <h2 data-aos="fade-up">About Us</h2>
      </div>

      <div className="treo-main">
        <div className='img-TM' data-aos="fade-right">
          <div className='treo-img-center'>
            <img src={ioc4} className='treo-img' />
          </div>
          <div className='name-TM' >
            <h2>World Wide Treding</h2><hr />
            <div className='treo-nt' >We operate through our modern pack house / cold storage facility for grapes,
              onion grading center & modern packing facility for Vegetable in Indapur, Pune (India) with Best Hygienic conditions.</div>
          </div>
        </div>
        <div className='img-TM' data-aos="fade-up">
          <div className='treo-img-center'>
            <img src={ioc5} className='treo-img' />
          </div>
          <div className='name-TM'>
            <h2>Quality Work</h2><hr />
            <div className='treo-nt'>Being quality conscious organization ,all our produce from associated growers are closely
              monitored in various growth stages till packaging, the produce can be tested in APEDA recognized laboratories as per customer’s need.</div>
          </div>
        </div>
        <div className='img-TM' data-aos="fade-left">
          <div className='treo-img-center'>
            <img src={ioc6} className='treo-img' />
          </div>
          <div className='name-TM'>
            <h2>Timely Delevery</h2><hr />
            <div className='treo-nt'> Export Packing is carried out in APEDA Approved pack house in hygienic
              packaging material to suit end users requirements for export as well as domestic market.</div>
          </div>
        </div>
      </div>


      <div className='fruits-main1-f1 '>
        <div className='fruit bg-section-color' data-aos="fade-right">
          <div className='f-img'>
            <div><img src={pvi1} className='pa-img1' alt='fruit-img' /></div>
          </div>
          <div className='f-text ps-1'>
            <h2 className='section-inner-head'>Fresh Fruits</h2>
            <br />
            <div className='bt '> Fresh fruits are nature's vibrant and nutritious gifts, a colorful array of wholesome delights. Packed with vitamins, minerals, antioxidants, and fiber, these succulent treasures offer a burst of flavor and health benefits. Consuming fresh fruits is not only a sensory pleasure but also a smart choice for maintaining a balanced and nutritious diet. Whether enjoyed on their own or incorporated into salads, smoothies, or desserts,
             fresh fruits remain a delicious and essential component of a healthy lifestyle.

            </div>
          </div>

        </div>

        <div className='fruit bg-section-color fruits-op' style={{ marginTop: 40 }} data-aos="fade-left">
          <div className='f-img'>
            <div><img src={pvi2
            } className='pa-img1' alt='fruit-img' /></div>
          </div>
          <div className='f-text ps-1'>
            <h2 className='section-inner-head'>Pulp</h2>
            <br />
            <div className='bt '> Fruit pulp is the delectable, juicy essence concealed within the protective embrace of a fruit's skin. Comprising the soft, fleshy part of the fruit that surrounds the seeds,
             if present, pulp is a reservoir of natural goodness. Bursting with vitamins, minerals, and succulent sweetness, it offers a sensory delight with every bite. Whether enjoyed fresh as part of the whole fruit or harnessed for culinary endeavors, fruit pulp is a versatile ingredient.


            </div>
          </div>
        </div>

      </div><br/>

      <div id='investmentinfo' style={{paddingTop:`40px`}}>
        <div className='service-parent-flex-center' style={{ flexDirection: `column` }}>
        {/* <h1 className='section-inner-head'>Empowering Agriculture: Our Comprehensive Services for Farmers</h1> */}

        <p className="services-text-heding">Empowering Agriculture: Our Comprehensive Services for Farmers</p>
       
        <div className="chooses-text-main">

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Cultivating Prosperity, Nurturing Growth: A Vision Beyond Business `}
            bodyMessage={`At Ancestral Farmer Producer Company, our commitment extends far beyond transactions – it's about serving our cherished farmer members and guiding them through every step of their farming journey. Recognizing the myriad challenges they face in cultivating quality produce, we stand as steadfast partners, ready to assist.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Seeds of Quality, Seeds of Success `}
            bodyMessage={`In our on-site Agriculture Input Shop, operating on a no-profit-no-loss principle, we provide farmers with access to top-quality seeds. Leveraging technology, our farmers can effortlessly manage inventory details through our user-friendly app, ensuring that every seed sown is a step towards a thriving harvest.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Caring for Mother Soil and Life-Giving Water `}
            bodyMessage={`Understanding the significance of soil as the mother of crops and water as their "Sanjivani," we've established a state-of-the-art Soil and Water Testing Laboratory. Farmers, facing unsuitable soil or water conditions, can initiate testing by raising a ticket through our app. Our representatives collect samples, and with detailed analysis and expert advisory, we guide farmers toward optimal soil and water health.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Innovative Finance Solutions for Agricultural Pioneers `}
            bodyMessage={`Financial stability is pivotal in farming, especially when our farmers are embracing innovative ideas. To support them during financial challenges, our tech-driven approach generates annual farm finance reports for each farmer. Based on this, we connect farmers with financial institutions, ensuring they have the funds needed to bring their innovative ideas to fruition.`}
          />

          <ListItem
            parentClass={`chooses-text-p`}
            preColHeading={`Handholding Through Innovation for Quality and Sustainability`}
            bodyMessage={` We firmly believe that innovation brings quality and sustainability to life. By handholding our farmers through financial challenges, providing cutting-edge technology, and fostering innovation in farming practices, we strive to elevate the standard of living for every farmer associated with us.`}
          />

        
        </div>
        <p className="services-text-paregraph at">
        At Ancestral Farmer Producer Company, our vision is to not just improve the life standard of farmers but to bring enduring sustainability to their lives. Together, let's cultivate a future where innovation and care for the land lead to prosperity. 🌾🌱
        </p>
       
        </div>

      </div>

      
      <br />
      <div className="chooses-video-main">

        <iframe className="chooses-video" src="https://www.youtube.com/embed/VCd_1UG4eJA?si=dKU3awBQKcOv1bIj"></iframe>
      </div>



      <div className='certificate-main bg-section-color'>
        <p className='certificate-heder-text section-heading'>Cerificate</p>

        <div className='certificate-sec-mains1'>
          <div className='certificate1 position-relative'>

            <div className='postion-absolute cer-left-arrow' onClick={handleLeft}><i class="fa-solid fa-backward fa-xl" style={{ color: '#007946' }}></i></div>

            <p className='certificate-text-1 section-inner-head'>{certArr[index].text}</p>
            <img src={certArr[index].image} className='certificate-img1 image-good-one' alt='certificate-img1' />

            <div className='postion-absolute cer-right-arrow' onClick={handleRight}><i class="fa-solid fa-forward fa-xl" style={{ color: '#007946' }}></i></div>
          </div>
        </div>


      </div>

    </section>
  )
  }
}

export default About