import React, { useEffect, useState } from "react";
import "../../assets/css/includes/productinfo/pomegranate.css";
import Loader from "../../screen/Loader";
import mango_img from "../../assets/images/Products/mango_img.png";
import mango_img1 from "../../assets/images/Products/mango1.jpg";
import mango_img2 from "../../assets/images/Products/mango2.jpg";
import mango_img3 from "../../assets/images/Products/mango3.jpg";
import mango_img4 from "../../assets/images/Products/mango4.jpg";
import mango_img5 from "../../assets/images/Products/mango5.jpg";
import mango_img6 from "../../assets/images/Products/mango6.jpg";
const Mango = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else {
    return (
      <div className="productinfo-container-main">
        <div className="productinfo-container">
          <div className="productinfo-text-main">
            <p className="productinfo-heder">MANGO FRUITS</p>
            <div className="productinfo-line" />
            <div className="productinfo-paregraph">
              <div className="productinfo-content-main">
                <div className="productinfo-content-main-text">
                  <p className="productinfo-paregraph-text1">
                    Export and Supplier of Alphanso and kesar Mango. Our range
                    of Mango is available with following features and
                    specifications
                  </p>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Major Export Variety:
                    </span>
                    <p className="productinfo-pr-text">Alphanso , Kesar</p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Packaging:</span>
                    <p className="productinfo-pr-text">
                      {" "}
                      Net weight of box 4.00 kg
                    </p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text1">Details:</span>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Size:</span>
                    <p className="productinfo-pr-text">Medium 250 gm & above</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Shape :</span>
                    <p className="productinfo-pr-text"> Ovate.</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Skin </span>
                    <p className="productinfo-pr-text">
                      {" "}
                      Medium Thick: Yellow.
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Taste: </span>
                    <p className="productinfo-pr-text">Sweet , Fibreless</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Availability: </span>
                    <p className="productinfo-pr-text">March, April, May.</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Health Benifits:{" "}
                    </span>
                    <p className="productinfo-pr-text text-p1">
                      Mango Protects Against Some Diseases. The fruit packs
                      plenty of polyphenols, which can be found in the peel,
                      pulp, and seed kernel of the fruit. The high vitamin C
                      content in mangoes is known to boost the immune system.
                      Mangoes are a good source of essential vitamins and
                      minerals, including vitamin C, vitamin A, vitamin E, and
                      potassium. Mangoes contain antioxidants such as
                      beta-carotene and quercetin, which help protect cells from
                      oxidative stress and inflammation. Antioxidants play a
                      role in reducing the risk of chronic diseases. mangoes may
                      have a positive impact on blood sugar levels, making them
                      potentially beneficial for individuals with diabetes.
                    </p>
                  </div>
                </div>
                <img src={mango_img} className="pome_img1" />
              </div>

              <div className="productinfo-line1" />
              <div className="productinfo-main-images1">
                <div className="productinfo-content-main1">
                  <img src={mango_img1} className="pome_img2" />
                  <img src={mango_img2} className="pome_img2" />
                  <img src={mango_img3} className="pome_img2" />
                  <img src={mango_img4} className="pome_img2" />
                  <img src={mango_img5} className="pome_img2" />
                  <img src={mango_img6} className="pome_img2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Mango;
