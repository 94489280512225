import React from 'react';
import '../assets/css/comman/card.css';
import logo from '../assets/images/pics/pulpimg.jpg';
import logo1 from '../assets/images/pics/fruitsimg.jpg';
import ButtonA from '../atoms/ButtonA';
import { Link } from 'react-router-dom';
const Card1 = (props) => {
    const scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    const handleExploreMoreClick = () => {
        scrollToTop();
    };


    return (
        <div className='Product-card bg-section-color '>

            <h2 className='section-heading'>Product</h2>

            <div className='main'  >
                <div className="from-Box py-1" data-aos="fade-right">
                    <img src={logo} alt="img" className="img1" />

                    <h2 className='text-t1s'>Pulp</h2>
                    <p className='at card-content' >Fruit pulp is the delectable, juicy essence concealed within the protective embrace of a fruit's skin.
                     Comprising the soft, fleshy part of the fruit that surrounds the seeds, if present, pulp is a reservoir of natural goodness.
                    
                    </p>
                    <br></br>
                    <Link to="/product1" scrollToTop style={{ textDecoration: `none` }}>
                    <button type="button" class="slide-btn-updated " onClick={handleExploreMoreClick}>
                        Explore More
                    </button>
                    </Link>
                </div>


                <div className="from-Box py-1" data-aos="fade-left">
                    <img src={logo1} alt="img" className="img1" />

                    <h2 className='text-t1s'>Fruits</h2>
                    <p className='at card-content' >Fresh fruits are nature's vibrant and nutritious gifts, a colorful array of wholesome delights.
                     Packed with vitamins,
                     minerals, antioxidants, and fiber, these succulent treasures offer a burst of flavor and health benefits.
                    </p>

                    <Link to="/product"  style={{ textDecoration: `none` }}>
                    <button type="button" class="slide-btn-updated " onClick={handleExploreMoreClick}>
                        Explore More
                    </button>
                    </Link>

                </div>

            </div>
        </div>
    );
}

export default Card1;