import React, { createContext, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Screen from "./components/screen/Screen";
import Home from "./components/container/home/Home";
import About from "./components/container/about/About"
import Product from "./components/container/products/Products";
import Product1 from "./components/container/products/products1";
import Pomegranate from "./components/includes/productinfo/Pomegranate";
import Banana from "./components/includes/productinfo/Banana";
import CusterdApple from "./components/includes/productinfo/Custerdapple";
import Guavas from "./components/includes/productinfo/Guava";
import Mango from "./components/includes/productinfo/Mango";
import Pomegranatepulps from "./components/includes/pulps/Pomegranatepulps"
import MangoPulps  from "./components/includes/pulps/Mangopulp";
import CusterdapplePulp from "./components/includes/pulps/Custerdapplepulp";
import Guavapulp from "./components/includes/pulps/Guavapulp";
import Bananapulps from "./components/includes/pulps/Bananapulps";
import WhyUs from "./components/container/whyus/WhyUS";
import Blogs from "./components/container/blogs/Blogs";
import Blog_detail from "./components/container/blogs/Blog_detail"
import Contact from "./components/container/contact/Contact";
// import OurStory from "./components/container/about/OurStory"
import MissionVision from "./components/container/about/mission";
import Team from "./components/container/about/Team"
import './App.css'
import Story from "./components/container/about/Story";
import Loader from "./components/screen/Loader";


export const Context = createContext();

function App() {
  // Define contextValue with the appropriate value or context
  const contextValue = 10;
  const [isloading, setloading] = useState(false)

  return (
    <Context.Provider value={contextValue}>
      <BrowserRouter>
        <Screen>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/our-story" element={<Story />} />
            <Route path="/mission-vision" element={<MissionVision />} />
  {/*<Route path="/team" element={<Team />} />*/}
            <Route path="/product" element={<Product />} />
            <Route path="/product1" element={<Product1 />} />
            <Route path="/pomegranate" element={<Pomegranate />} />
            <Route path="/banana" element={<Banana/>} />
            <Route path="/custerdapple" element={<CusterdApple />} />
            <Route path="/mango" element={<Mango />} />
            <Route path="/guavas" element={<Guavas />} />
            <Route path="/Pomegranatepulps" element={<Pomegranatepulps />} />
            <Route path="/Mangopulps" element={<MangoPulps />} />
            <Route path="/custerdapplepulp" element={<CusterdapplePulp />} />
            <Route path="/bananapulps" element={<Bananapulps />} />
            <Route path="/guavapulp" element={<Guavapulp />} />
            <Route path="/whyus" element={<WhyUs />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blog_detail" element={<Blog_detail />} />
           
          </Routes>
        </Screen>
      </BrowserRouter>
    </Context.Provider>
  );
}

export default App;
