import React from 'react'
import '../../assets/css/includes/pulpsinfo.css';
import pomepulps_img from '../../assets/images/Products/pomegranate_pulp_img.png';

const Pomegranatepulps = () => {
  return (
    <div className="pulpstinfo-container-main">
      <div className="pulpsinfo-container">
        <div className='pulpsinfo-text-main'>
          <p className='pulpsinfo-heder'>POMEGRANATE PULP </p>
          <div className='pulpsinfo-line' />
          <div className='pulpsinfo-paregraph'>


            <div className='pulpsinfo-content-main'>
              <div className='pulpsinfo-content-main-text'>
                <p className='pulpsinfo-paregraph-text1'> Product Details:</p>
                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Type:</span>
                  <p className='pulpsinfo-pr-text'>Pomegranate Pulp
                  </p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Part:</span>
                  <p className='pulpsinfo-pr-text'>Peeled</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Color:</span>
                  <p className='pulpsinfo-pr-text'>Red</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Taste:</span>
                  <p className='pulpsinfo-pr-text'>Sweet</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Freezing Process:</span>
                  <p className='pulpsinfo-pr-text'> BQF</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Shelf Life: </span>
                  <p className='pulpsinfo-pr-text'> 2 Months</p>
                </div>

                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Features:</span>
                  <p className='pulpsinfo-pr-text '>
                    1) Extracted from Fresh and matured Pomegranate
                    <br />
                    2) Original Taste & flavor
                    <br />
                    3) Availability around the year
                  </p>
                </div>
                <div className='pulpsinfo-texts1'>
                  <span className='pulpsinfo-snp-text'>Product Description: </span>
                  <p className='pulpsinfo-pr-text text-p1'>Pomegranate Pulp - Pomegranate Puree is higher in overall phenolic content and antioxidant activity i.e. anthocyanins, ellagic acid derivatives, and hydrolyzable tannins than red wine, Concord grape, blueberry and orange juices. Its rich taste and mouth feel make it ideal for smoothies and similar preparations.

                  </p>
                </div>

              </div>

              <img src={pomepulps_img} className='pulpspome_img1' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Pomegranatepulps