import React, { useEffect, useState } from "react";
import story1 from "../../assets/images/Story/story1.jpg";
import story2 from "../../assets/images/Story/story2.jpg";
import story3 from "../../assets/images/Story/story3.jpg";
import story4 from "../../assets/images/Story/story4.jpg";
import story5 from "../../assets/images/Story/story5.jpg";
import story6 from "../../assets/images/Story/story6.jpg";
import story7 from "../../assets/images/Story/story7.jpg";
import Loader from "../../screen/Loader";
import "../../assets/css/containers/story.css";

import backimg from "../../assets/images/pics/fruitsimg.jpg";

const WhyUs = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else {
    return (
      <section className="our-story">
        <div className="about-img-fluid">
          <h2 data-aos="fade-up">OUR STORY</h2>
        </div>

        <div className="step-1-head" data-aos="fade-up">
          Let us have a look at our story, right from the beginning.
        </div>
        <div className="step-1-body" data-aos="fade-right">
          <div className="timeline">
            <div className="timeline-box left">
              <div className="timeline-container">
                <div className="story-main-l1">
                  <img src={story1} className="story-img1" alt={"logo"} />
                  <div className="story-main-box">
                    <p className="story-main-text-1">
                      I cant get the Right Price for it
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline-box right" data-aos="fade-left">
              <div className="timeline-container">
                <div className="story-main-l1">
                  <img src={story2} className="story-img1" alt={"logo"} />
                  <div className="story-main-box">
                    <p className="story-main-text-1">Middleman Exploiys Me</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline-box left" data-aos="fade-right">
              <div className="timeline-container">
                <div className="story-main-l1">
                  <img src={story3} className="story-img2" alt={"logo"} />
                  <div className="story-main-box">
                    <p className="story-main-text-2">
                      Harvest is facing lossess why am i alwayas in debt.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="timeline-divider">
              <div className="timeline-traveller">
                <div>{/*<i class="fa-solid fa-basket-shopping"></i>*/}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="step-2-head" data-aos="fade-up">
          This is the story of small-landholding farmers. But watch what happens
          when they come together.
        </div>
        <div className="step-2-body" data-aos="fade-up">
          <img src={story4} className="story-img1" alt={"logo"} />
          <div className="story-main-box-b1">
            <p className="story-main-text-3">
              What if come together ? Invest in common infrastructure, and
              collectively nigotiate
            </p>
          </div>
        </div>

        <div className="step-3-head" data-aos="fade-up">
          Farmers come together and register a collective and become
          stakeholders
        </div>
        <div className="step-3-body">
          <div className="timeline-tl">
            <div className="timeline-box left" data-aos="fade-right">
              <div className="timeline-container">
                <div className="story-main-l1">
                  <img src={story5} className="story-img2" alt={"logo"} />
                  <div className="story-main-box-bl1">
                    <p className="story-main-text-4">
                      Are you wondering whether farming is the right career
                      choice for you in the first place.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="timeline-box right" data-aos="fade-left">
              <div className="timeline-container">
                <div className="story-main-l1">
                  <img src={story6} className="story-img3" alt={"logo"} />
                  <div className="story-main-box-bl2">
                    <p className="story-main-text-4">
                      If you are still wating on the perfect parcel of land to
                      pop up, then one thing you can do in the meantime is to
                      learn more about farming
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div id="timeline-divider" style={{ height: `47%`, top: `15%` }}>
              <div className="timeline-traveller">
                <div>{/*<i class="fa-solid fa-basket-shopping"></i>*/}</div>
              </div>
            </div>
          </div>

          <div className="story-main-footer" data-aos="fade-up">
            <img src={story7} className="story-img4" alt={"logo"} />
            <p className="story-main-text-4">
              Now,farmers can reach directly to consumers.
            </p>
            <div className="story-text">
              <p className="step-3-head">
                End product reaching you via e-commerce, retail stores and
                export
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default WhyUs;
