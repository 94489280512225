import React , {useEffect , useState} from 'react'
import contact from '../../assets/images/Contact-Page/Contactpage-Slider.jpg'
import '../../assets/css/containers/mission.css';
import MISSION from '../../assets/images/logo/about-pic/Mission.jpg'
import Vission from '../../assets/images/logo/about-pic/Vision.jpg'
import STORAGE from '../../assets/images/logo/about-pic/Exporthouse.jpg'
import UNIT from '../../assets/images/logo/about-pic/Values.jpg'
import FRUITS from '../../assets/images/logo/about-pic/FruitV.jpg'
import Loader from '../../screen/Loader';
const WhyUs = () => {

  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if(showPageLoader){
    return <Loader />
  }else{
    return (
        <section className="mission-main">
            <div className="mision-img-fluid about-img-fluid">
                <h2 style={{ marginTop: -50 }} data-aos="fade-up">Our Mission and Vision</h2>
            </div>
  
  
            <div className="mission-l1">
                <div className="mission-l1 mission-heder-text-t1" data-aos="fade-right">
                    <img src={MISSION} className="mission-logo1" alt={"logo"} />
                    <p className="mission-text-p1">MISSION</p>
                    <p className="mission-text-p3">Empowering farmers transforms lives. Our mission is to uplift farmer members and provide them with the training and assistance they need to thrive as effective enterprises.
                     Together, we can create a brighter future for farming communities.</p>
                </div>
  
                <div className="mission-l1  mission-heder-text-t1" data-aos="fade-left">
                    <img src={Vission} className="mission-logos1" alt={"logo"} />
                    <p className="mission-text-p1">VISION</p>
                    <p className="mission-text-p3">To handhold and guide the farmers to grow into effective business enterprises working for the socio-economic advancement of their member farmers.
                     And to act as a think tank for guiding policy and developmental interventions aimed at producer enterprises in general.</p>
                </div>
            </div>
  
            <div className="mission-l1 ls-1 bg-section-color" data-aos="fade-right">
                <img src={UNIT} className="mission-logo " alt={"logo"} />
                <div className="mission-content">
                    <p className="mission-text-p1 psc1">COLD STORAGE</p>
                    <p className="mission-text-p2">A cold storage facility specifically designed for preserving fresh fruits such as pomegranates, bananas, mangoes, guavas, and custard apples. Additionally, an integrated pomegranate processing unit will be incorporated, encompassing essential facilities such as a pre-cooling unit,
                     sorting and grading unit, cold storage unit, and processing unit.</p>
                </div>
            </div>
  
            <div className="col-rev mission-l1 ls-1" data-aos="fade-left">
  
                <div className="mission-content">
                    <p className="mission-text-p1 psc1">POMEGRANATE PROCESSING UNIT</p>
                    <p className="mission-text-p2">The pomegranate processing unit within our envisioned facility is designed to elevate the handling of this unique fruit. Equipped with cutting-edge technology, the unit facilitates precise sorting, cleaning, and packaging of pomegranates,
                     ensuring the preservation of their natural attributes. From meticulous sorting to stringent quality control measures, the processing unit aims to enhance the overall quality and market appeal of pomegranates. 
                    By integrating advanced machinery and adhering to the highest standards of hygiene, our pomegranate processing unit stands as a testament to our commitment to delivering premium agricultural products to discerning markets.</p>
                </div>
                <img src={STORAGE} className="mission-logo " alt={"logo"} />
            </div>
  
  
            <div className="mission-l1 ls-1 bg-section-color" data-aos="fade-right">
                <img src={FRUITS} className="mission-logo" alt={"logo"} />
                <div className="mission-content">
                    <p className="mission-text-p1 psc1">EXPORT HOUSE FOR FRUITS</p>
                    <p className="mission-text-p2">Our export house specializes in distributing premium fresh fruits globally, 
                    sourced directly from trusted growers. We ensure the entire export process is handled meticulously in our state-of-the-art facility, 
                    preserving freshness and nutritional value. Rigorous quality control measures exceed international standards, instilling confidence in our superior offerings. 
                    Dedicated to fresh fruits,
                     we contribute to the global supply chain by delivering nutritious produce worldwide.</p>
                </div>
            </div>
  
  
        </section>
  
    )
  }

}

export default WhyUs