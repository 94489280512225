import React, { useEffect, useState } from "react";
import "../../assets/css/includes/productinfo/pomegranate.css";
import Loader from "../../screen/Loader";
import banana_img from "../../assets/images/Products/banana_img.png";
import banana_img1 from "../../assets/images/Products/banana1.jpg";
import banana_img2 from "../../assets/images/Products/banana2.jpg";
import banana_img3 from "../../assets/images/Products/banana3.jpg";
import banana_img4 from "../../assets/images/Products/banana6.jpg";
import banana_img5 from "../../assets/images/Products/banana5.jpg";
import banana_img6 from "../../assets/images/Products/banana4.jpg";
const Banana = () => {
  const [showPageLoader, setShowPageLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowPageLoader(false);
    }, 1650);
  }, []);

  if (showPageLoader) {
    return <Loader />;
  } else {
    return (
      <div className="productinfo-container-main">
        <div className="productinfo-container">
          <div className="productinfo-text-main">
            <p className="productinfo-heder">BANANA FRUITS</p>
            <div className="productinfo-line" />
            <div className="productinfo-paregraph">
              <div className="productinfo-content-main">
                <div className="productinfo-content-main-text">
                  <p className="productinfo-paregraph-text1">
                    Fresh Banana is one of the important fruit crops
                    commercially grown in the state of Maharashtra. The main
                    commercially grown varieties are G9 and Basrai.
                  </p>
                  <br />
                  <p className="productinfo-paregraph-text1">
                    {" "}
                    G9 Cavendish Green Banana.
                  </p>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Major Export Variety:
                    </span>
                    <p className="productinfo-pr-text">
                      G9 Cavendish Green Banana.
                    </p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Packaging:</span>
                    <p className="productinfo-pr-text">
                      {" "}
                      Net weight of box 13-13.5 Kg in plastic carets.
                    </p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Packing Specification :
                    </span>
                    <p className="productinfo-pr-text no-text1">
                      1) 48.25cm X 31.75cm X 20.25cm-5 ply Bottom= 47.50 X
                      31.25cm X 19.75cm-5ply
                      <br />
                      2) Gap plate= 3 ply Foam sheet or foam pad= 20mm thick
                      <br />
                      3) 38cm X 25cm size with 10 mm holes Weight of the final
                      packed box is approximately 13.0 Kg
                    </p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Details:</span>
                    <p className="productinfo-pr-text">
                      Minimum Weight 180gm, maximum weight 400gm
                    </p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Colour of arils:
                    </span>
                    <p className="productinfo-pr-text"> Green and Yellow.</p>
                  </div>

                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">T.S.S.: </span>
                    <p className="productinfo-pr-text"> 19.50–74.03%</p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Acidity: </span>
                    <p className="productinfo-pr-text">4.5 – 5.5</p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Taste: </span>
                    <p className="productinfo-pr-text">Sweet</p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">Availability: </span>
                    <p className="productinfo-pr-text">All Year</p>
                  </div>
                  <div className="productinfo-texts1">
                    <span className="productinfo-snp-text">
                      Health Benifits:{" "}
                    </span>
                    <p className="productinfo-pr-text text-p1">
                      Bananas are a good source of potassium, magnesium, vitamin
                      B6, and other important nutrients. They're good for
                      digestive and heart health. You can eat this tropical
                      fruit raw, or add it to smoothies or baked goods. Eating
                      one banana per day is a healthy option , Tryptophan is an
                      essential amino acid that helps the body produce
                      serotonin, a brain chemical that regulates sleep
                    </p>
                  </div>
                </div>
                <img src={banana_img} className="pome_img1" />
              </div>

              <div className="productinfo-line1" />
              <div className="productinfo-main-images1">
                <div className="productinfo-content-main1 ">
                  <img src={banana_img1} className="pome_img2" />
                  <img src={banana_img2} className="pome_img2" />
                  <img src={banana_img3} className="pome_img2" />
                  <img src={banana_img4} className="pome_img2" />
                  <img src={banana_img5} className="pome_img2" />
                  <img src={banana_img6} className="pome_img2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Banana;
