import React, { useEffect, useState } from 'react'
import '../../assets/css/containers/product.css'

import productl1 from '../../assets/images/product_img-1.jpg';
import FruitProduct from '../../common/FruitProduct';
import friutproduct1 from '../../assets/images/Products/pomo8.png';
import friutproduct2 from '../../assets/images/Products/banana2-removebg-preview.png';
import friutproduct3 from '../../assets/images/Products/custard-apple-removebg-preview.png';
import friutproduct4 from '../../assets/images/Products/mango_img.png';
import friutproduct5 from '../../assets/images/Products/guava_img.png';


import { useNavigate } from 'react-router-dom';
import Loader from '../../screen/Loader';
import { scrollToTop } from '../../utils/scrollToTop'
const Product = () => {
  const navigate = useNavigate();
  const [showPageLoader , setShowPageLoader] = useState(true)


  const navigateToPomegranate = () => {
    navigate('/pomegranate');
    scrollToTop();
  };
  const navigateToBanana = () => {
    navigate('/banana');
    scrollToTop();
  };
  const navigateToCusterdapple = () => {
    navigate('/custerdapple');
    scrollToTop();
  };
  
  const navigateToMango = () => {
    navigate('/mango');
    scrollToTop();
  };
  const navigateToGuava = () => {
    navigate('/guavas');
    scrollToTop();
  };

  


  useEffect(()=>{
    setTimeout(()=>{setShowPageLoader(false)},1650)
  },[])

  if(showPageLoader){
    return <Loader />
  }else{
  return (
    <section className="product-section-mt-5 product-pt-4 product-pb-5 product-div">
      <div className="product-container">

        <div className="products-img-fluid about-img-fluid">
          <h2 style={{ marginTop: -50 }} data-aos="fade-up">FRUITS</h2>
        </div>

        <div className="product-Fruit-container-main">

          <p className="product-Fruit-heding-text section-heading">Fresh Fruits</p>

          <div className="product-Fruit-main">

            {/*<img src={productl1} className="product-Fruit-img-1" alt={"logo"} />*/}

            <div className="product-Fruit-text1">
              <p className="product-Fruit-text-p1 at">Fresh fruits are nature's vibrant and nutritious gifts, a colorful array of wholesome delights. Packed with vitamins, minerals, antioxidants, and fiber, these succulent treasures offer a burst of flavor and health benefits. Consuming fresh fruits is not only a sensory pleasure but also a smart choice for maintaining a balanced and nutritious diet. Whether enjoyed on their own or incorporated into salads, smoothies, or desserts,
             fresh fruits remain a delicious and essential component of a healthy lifestyle.
              </p>
            </div>
          </div>

          <div className="product-Fruitf1-main">
            <FruitProduct
              title="Pomegranate"
              image={friutproduct1}
              description="Freshest Fruits, Finest Flavors Nature's Bounty in Every Bite!"
              additionalClassName="my-custom-class1"
              boxClassName="friutp1"
              handleClick={() => navigateToPomegranate()}

            />
            <FruitProduct
              title="Banana"
              image={friutproduct2}
              handleClick={() => navigateToBanana()} 
              description="Freshest Fruits, Finest Flavors Nature's Bounty in Every Bite!."
              additionalClassName="my-custom-class2"
              boxClassName="friutp2"
            />
            <FruitProduct
              title="Custerd apple"
              image={friutproduct3}
              handleClick={() => navigateToCusterdapple()}
              description="Freshest Fruits, Finest Flavors Nature's Bounty in Every Bite!"
              additionalClassName="my-custom-class3"
              boxClassName="friutp3"
            />
          </div>
          <div className="product-Fruitf1-main1">
            <FruitProduct
              title="Mango"
              image={friutproduct4}
          
              handleClick={() => navigateToMango()} 
              description="Freshest Fruits, Finest Flavors Nature's Bounty in Every Bite!"
              additionalClassName="my-custom-class4"
              boxClassName="friutp4"
            />
            <FruitProduct
              title="Guava"
              image={friutproduct5}
              
              handleClick={() => navigateToGuava()} 
              description="Freshest Fruits, Finest Flavors Nature's Bounty in Every Bite!"
              additionalClassName="my-custom-class5"
              boxClassName="friutp5"
            />

          </div>
        </div>
        
      </div>
    </section>
  )
  }
}

export default Product