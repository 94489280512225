import React, { useEffect, useState } from 'react';
import '../../assets/css/includes/productinfo/pomegranate.css';
import Loader from '../../screen/Loader';
import guavas_img from '../../assets/images/Products/guavas.jpeg';
import guavas_img1 from '../../assets/images/Products/guava1.jpg';
import guavas_img2 from '../../assets/images/Products/guava2.jpg';
import guavas_img3 from '../../assets/images/Products/guava3.jpg';
import guavas_img4 from '../../assets/images/Products/guava4.jpg';
import guavas_img5 from '../../assets/images/Products/guava5.jpg';
import guavas_img6 from '../../assets/images/Products/guava6.jpg';
const Guava = () => {

    const [showPageLoader, setShowPageLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
          setShowPageLoader(false);
        }, 1650);
      }, []);

      if (showPageLoader) {
        return <Loader />;
      } else{
    

    return (
        <div className="productinfo-container-main">
            <div className="productinfo-container">
                <div className='productinfo-text-main'>
                    <p className='productinfo-heder'>GUAVA FRUITS</p>
                    <div className='productinfo-line' />
                    <div className='productinfo-paregraph'>


                        <div className='productinfo-content-main'>
                            <div className='productinfo-content-main-text'>
                                <p className='productinfo-paregraph-text1'>Fresh Guava is one of the important fruit crops commercially grown in the state of Maharashtra.
                                    The main commercially grown varieties are Dholka, Kothrud, L-24, L-49, Nagpur seedless, Dharwar, Nasik, and Sindh.</p>
                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Major Export Variety:</span>
                                    <p className='productinfo-pr-text'>Dholka, Kothrud, L-24, L-49, Nagpur seedless, Dharwar, Nasik, and Sindh.</p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Packaging:</span>
                                    <p className='productinfo-pr-text'> Net weight of box 10 Kg in plastic carets.</p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Fruit count/Box (10 kg net wt.):</span>
                                    <p className='productinfo-pr-text'>
                                        1) 200 to 240 grm-2 Count
                                        <br />
                                        2) 240 to 275 grm-3 Count
                                        <br />
                                        3) 275 to 325 grm-5 Count
                                        <br />
                                        4) 325 Above-8/12 Count
                                    </p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Details:</span>
                                    <p className='productinfo-pr-text'>Minimum Weight 105gm, maximum weight 400gm
                                    </p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Colour of arils:</span>
                                    <p className='productinfo-pr-text'> Green and yellow</p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>T.S.S.:</span>
                                    <p className='productinfo-pr-text'> 12 - 14 0B
                                    </p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Acidity: </span>
                                    <p className='productinfo-pr-text'>0.20-0.35</p>
                                </div>


                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Taste: </span>
                                    <p className='productinfo-pr-text'>Sweet and soft, bitter taste</p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Availability: </span>
                                    <p className='productinfo-pr-text'>May and June</p>
                                </div>

                                <div className='productinfo-texts1'>
                                    <span className='productinfo-snp-text'>Health Benifits: </span>
                                    <p className='productinfo-pr-text text-p1'>Guavas are a nutritional powerhouse, offering a multitude of health benefits. Packed with vitamin C, fiber, and antioxidants,
                                        guavas support immune function, aid digestion, and contribute to skin health. The fruit's high levels of dietary fiber help regulate blood sugar levels and promote a healthy digestive system.
                                        Additionally, guavas contain various vitamins and minerals, including potassium, promoting heart health.</p>
                                </div>

                            </div>
                            <img src={guavas_img} className='pome_img1' />
                        </div>

                        <div className='productinfo-line1' />
                        <div className='productinfo-main-images1'>
                            <div className='productinfo-content-main1'>
                                <img src={guavas_img1} className='pome_img2' />
                                <img src={guavas_img2} className='pome_img2' />
                                <img src={guavas_img3} className='pome_img2' />
                                <img src={guavas_img4} className='pome_img2' />
                                <img src={guavas_img5} className='pome_img2' />
                                <img src={guavas_img6} className='pome_img2' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
      }
}

export default Guava;
