import React, { useEffect, useState } from 'react'
import Card from '../../common/Card1'
import ImageSlider from '../../includes/Slider';
import { SliderDataForLargeArea, SliderDataForSmallArea } from '../../includes/SliderData';
import '../../assets/css/includes/slider.css'
import ioc1 from '../../assets/images/pics/icon4.png';
import ioc2 from '../../assets/images/pics/icon4.png';
import ioc3 from '../../assets/images/pics/icon4.png';
import pvi1 from '../../assets/images/pics/pulpimg.jpg';
import pvi2 from '../../assets/images/pics/fruitsimg.jpg';
import CounterSection from '../../common/CounterSection';
import whyus from '../../assets/images/pics/fruitsimg.jpg'
import '../../assets/css/containers/home.css';
import '../../assets/css/containers/contact.css'
import ButtonA from '../../atoms/ButtonA';
import imge from '../../assets/images/Contact-Page/ContactFruit-img.png'
import { BsGeoAlt, BsEnvelope } from "react-icons/bs";
import { BiPhoneCall } from "react-icons/bi";
import { faClock, faUsers, faCubes, faGlobe } from '@fortawesome/free-solid-svg-icons';
import ContactForm from '../../common/ContactForm';
import Loader from '../../screen/Loader';
import { Link } from 'react-router-dom';



const Home = () => {

  const [SliderData, setSliderData] = useState(SliderDataForLargeArea)
  const [showPageLoader , setShowPageLoader] = useState(true)

  const initialCounters = [
    { label: 'Year of Experience', icon: faClock, count: 0 },
    { label: 'Clients', icon: faUsers, count: 0 },
    { label: 'Product', icon: faCubes, count: 0 },
    { label: 'Serving Nations', icon: faGlobe, count: 0 },
  ];

  const targetValues = [
    initialCounters[0].count + 5,
    initialCounters[1].count + 40,
    initialCounters[2].count + 60,
    initialCounters[3].count + 10,
  ];
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setemail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [message, setMessage] = useState('');



  useState(() => {
    // console.log('WIndow WIdth is ', window.innerWidth)
    if (window.innerWidth <= 768) {
      setSliderData(SliderDataForSmallArea)
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const handleReadMoreClick = () => {
    scrollToTop();
  };

  useEffect(()=>{
    setTimeout(()=>{setShowPageLoader(false)},1650)
  },[])

  if(showPageLoader){
    return <Loader />
  }else{

    return (
      <section className=''>
        <ImageSlider slides={SliderData} />
  
        <div className='home-header'>Welcome To Oriphal</div>
  
        <div className="treo-main">
          <div className='img-TM' data-aos="fade-right">
            <div className='treo-img-center'>
              <img src={ioc1} className='treo-img' />
            </div>
            <div className='name-TM' >
              <h2>Infratructure</h2><hr />
              <div className='treo-nt' >At Oriphal Farmer Producer Company, our advanced facilities exemplify a commitment to excellence in fruit processing, ensuring top-tier quality. Specialized areas for state-of-the-art Fruit Processing and Cold Storage reflect our dedication. Our conscientious approach extends to an area dedicated to international packaging. 
              Experience premium fruit products with exquisite natural flavors.</div>
            </div>
          </div>
  
          <div className='img-TM' data-aos="fade-up">
            <div className='treo-img-center'>
              <img src={ioc1} className='treo-img' />
            </div>
            <div className='name-TM'>
              <h2>Quality Assurance</h2><hr />
              <div className='treo-nt'>Driven by an unwavering commitment to excellence, we employ cutting-edge technology to ensure meticulous monitoring of farmer practices for unparalleled quality. Transparency is paramount, granting customers easy access to origin details. 
              Our dedicated team delicately handpicks fruits, embodying our motto: deliver premium quality, inspire faith, and assure the absolute best for our customers </div>
            </div>
          </div>
  
          <div className='img-TM' data-aos="fade-left">
            <div className='treo-img-center'>
              <img src={ioc1} className='treo-img' />
            </div>
            <div className='name-TM' >
              <h2>Packaging</h2><hr />
              <div className='treo-nt'> Ensuring perfection in packaging, all our products undergo meticulous processing in APEDA-approved facilities, utilizing hygienic materials. 
              Whether destined for global exports or local markets, our commitment to quality packaging remains unwavering.</div>
            </div>
          </div>
        </div>
  
  
        <div className='bg-section-color' data-aos="fade-down">
          <CounterSection
            initialCounters={initialCounters}
            targetValues={targetValues}
            intervalDuration={2000}
          />
        </div>
  
  
        <div className='about-sec'>
          <div className='pv-1' data-aos="fade-right">
            <div><img src={pvi1} className='pv-img1' /></div>
            <div className='about-pv-img2'><img src={pvi2} className='pv-img1' /></div>
          </div>
          <div className='ab-text' data-aos="fade-left">
            <h2 className='section-heading'>About Us</h2>
            <br />
            <div className='at'>
             Oriphal Farmer Producer Company Limited is revolutionizing the agricultural industry with its innovative approach to farming and processing. Our facilities include dedicated spaces for state-of-the-art Fruit Processing and cutting-edge Cold Storage.
              <br />
              As proud manufacturers of high-quality Fruit Pulp, our journey begins with over 262 farmer members within our company and an additional 200+ non-member farmers, cultivating fruits through sustainable and responsible practices.
              <br />
              At the heart of our production lies our flagship product, Pomegranate Juice, crafted with precision and care. We meticulously monitor the entire journey of our fruits, from the farms to your table, guaranteeing transparency and accountability.
              <br />
              Committed to excellence, we prioritize providing our valued customers with unparalleled quality fruits and pulps. To facilitate international reach, we boast a 3,000 square feet packaging area dedicated to the export of our exquisite fruit products.
              <br />
              Join us on a flavorful journey as we bring the richness of nature to your palate with our premium fruit and pulp offerings.
  
            </div>
            <Link to="/our-story" style={{ textDecoration: `none` }}><button type="button" class="slide-btn-updated " onClick={handleReadMoreClick}>
              Our Story
            </button></Link>
          </div>
        </div>
  
        <Card />
  
  
        <div className='whyus-container'  >
          <div className='whyus-img'>
            <div className='whyus-text' data-aos="fade-up">
              <h2 className='section-heading'>Why Us</h2>
              <br />
              <div className='wt'>
              Our company, specializing in fresh fruits and pulp, takes pride in a meticulous approach to sourcing.
               We hand-select our fruits directly from partner farmers, establishing a strong network built on trust and integrity.
                Our commitment to quality extends beyond mere appearance, emphasizing high nutritional values in every fruit we procure.
                 By ensuring that our selection process prioritizes both flavor and nutritional content,
               we aim to deliver products that not only satisfy taste buds but also contribute to a healthier lifestyle.
              </div>
              <Link to="/whyus" style={{ textDecoration: `none` }}>
                <button type="button" class="slide-btn-updated" onClick={handleReadMoreClick}>
                  Read More
                </button></Link>
            </div>
          </div>
        </div>
  
        <div className="contact-Main-m3">
          <div className="contact-col-md-6  contactCSS" data-aos="fade-right">
            <h2 className="section-heading">Contact US</h2>
            <hr className="hrCSS" />
            <p className="contact-t1">Questions, comments, or suggestion? Simply fill in the <br />form and we'll be in touch shortly.</p>
          </div>
  
          <div className="contact-from-group-main1">
            <ContactForm />
          </div>
  
        </div>
        <div className="contact-box-M2 bg-section-color" >
          <div className="contact-box-b1 bl-1" data-aos="fade-right">
            <BsGeoAlt className="contact-icon-m1" />
            <h1>Location</h1>
            <p className="contact-t2">Chaitanya Mega Market Indapur, Pune 413106.</p>
          </div>
  
          <div className="contact-box-b1 bl-2" data-aos="fade-up">
            <BiPhoneCall className="contact-icon-m1" />
            <h1>Phone Number</h1>
            <p className="contact-t2">+91 7066969991 </p>
          </div>
  
          <div className="contact-box-b1 bl-3" data-aos="fade-left">
            <BsEnvelope className="contact-icon-m1 l1" />
            <h1>Email</h1>
            <p className="contact-t2">info@oriphal.com<br/>contact@oriphal.com</p>
          </div>
        </div>
  
        <br />
  
      </section>
  
  
    )
  }
}

export default Home