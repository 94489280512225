import Banana from '../assets/images/Slider-img/Banana.jpg'
import Guava from '../assets/images/Slider-img/Guava.jpg'
import CustardApple from '../assets/images/Slider-img/CustardApple.jpg'
import Pomegranate from '../assets/images/Slider-img/Pomegranate.jpg'
import Mango from '../assets/images/Slider-img/Mango.jpg'
import BananaM from '../assets/images/Slider-img/BananaM1.jpg'
import GuavaM from '../assets/images/Slider-img/GuaveM1.jpg'
import CustardM from '../assets/images/Slider-img/CustardM1.jpg'
import PomegranateM from '../assets/images/Slider-img/PomegranateM1.jpg'
import MangoM from '../assets/images/Slider-img/mangoM1.jpg'
export const SliderDataForLargeArea = [
    {
      image:Mango
    },
    {
      image:CustardApple
    },
    {
      image:Guava
    },
    {
      image:Pomegranate
    },
    {
      image:Banana
    }
  ];
export const SliderDataForSmallArea = [
    {
      image:MangoM
    },
    {
      image:CustardM
    },
    {
      image:GuavaM
    },
    {
      image:PomegranateM
    },
    {
      image:BananaM
    }
  ];