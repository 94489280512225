import React, { useEffect, useState } from 'react';
import '../../assets/css/includes/pulpsinfo.css';
import pomepulps_img from '../../assets/images/Products/banana_pulp_img.png';
import Loader from '../../screen/Loader';

const CusterdapplePulp = () => {

    const [showPageLoader, setShowPageLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
          setShowPageLoader(false);
        }, 1650);
      }, []);

      if (showPageLoader) {
        return <Loader />;
      } else{
    
    return (
        <div className="pulpstinfo-container-main">
            <div className="pulpsinfo-container">
                <div className='pulpsinfo-text-main'>
                    <p className='pulpsinfo-heder'>Custard appel </p>
                    <div className='pulpsinfo-line' />
                    <div className='pulpsinfo-paregraph'>


                        <div className='pulpsinfo-content-main'>
                            <div className='pulpsinfo-content-main-text'>
                                <p className='pulpsinfo-paregraph-text1'> Product Details:</p>
                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Type:</span>
                                    <p className='pulpsinfo-pr-text'>Custard apple Pulp</p>
                                </div>

                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Part:</span>
                                    <p className='pulpsinfo-pr-text'>Peeled</p>
                                </div>

                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Color:</span>
                                    <p className='pulpsinfo-pr-text'>Cream</p>
                                </div>

                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Taste:</span>
                                    <p className='pulpsinfo-pr-text'>Sweet</p>
                                </div>

                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Freezing Process:</span>
                                    <p className='pulpsinfo-pr-text'> BQF</p>
                                </div>

                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Shelf Life: </span>
                                    <p className='pulpsinfo-pr-text'> 18 Months</p>
                                </div>

                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Features:</span>
                                    <p className='pulpsinfo-pr-text '>
                                        1) Extracted from Fresh and matured custard
                                        <br />
                                        2) Original Taste & flavor
                                        <br />
                                        3) Availability around the year
                                    </p>
                                </div>

                                <div className='pulpsinfo-texts1'>
                                    <span className='pulpsinfo-snp-text'>Product Description: </span>
                                    <p className='pulpsinfo-pr-text text-p1'>We have emerged as one of the leading manufacturers & suppliers of the best quality Custard Apple Pulp.
                                        Matured Custards are processed so that their seeds are separated without damaging the original pulp contents retaining the original color, taste, & flavor.
                                        Pulp is hygienically packed in an airtight polythene bag & frozen in plate freezers to enhance its shelf life. Customers can avail of this product at market-leading prices.</p>
                                </div>

                            </div>

                            <img src={pomepulps_img} className='pulpspome_img1' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
      }
}

export default CusterdapplePulp;
